import { render, staticRenderFns } from "./newMaterialListV2.vue?vue&type=template&id=f4c1209a&scoped=true&"
import script from "./newMaterialListV2.vue?vue&type=script&lang=ts&setup=true&"
export * from "./newMaterialListV2.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./newMaterialListV2.vue?vue&type=style&index=0&id=f4c1209a&prod&scoped=true&lang=less&"
import style1 from "./newMaterialListV2.vue?vue&type=style&index=1&id=f4c1209a&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4c1209a",
  null
  
)

export default component.exports